@import "../../../core/styles/resources/media";
@import "../../../core/styles/resources/ag-grid";

.visx-tooltip {
  z-index: 9999; /* or whatever height is appropriate */
}

:root {
  .mapboxgl-control-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .mapboxgl-ctrl-top-right {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 24px;

    .mapboxgl-ctrl {
      margin: 0;
    }
  }

  #top-left-control-container {
    margin: 0;
  }

  .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl-group {
      margin: 0;

      // Ignore mapbox ad
      &:not(:last-of-type) {
        margin-right: 24px;
        margin-bottom: 16px;
      }
    }

    .mapboxgl-ctrl {
      margin: 0;
    }
  }

  .mapboxgl-ctrl button:not(:disabled):hover {
    background-color: var(--mantine-color-neutral-7);
  }

  .mapbox-ctrl-bottom-right-control-container:not(:empty) {
    margin-right: 24px !important;
    margin-bottom: 16px !important;
  }

  .mapboxgl-ctrl {

    &-zoom-in,
    &-zoom-out {
      // Sizings
      width: 32px;
      height: 32px;
      background-color: var(--mantine-color-neutral-9);
      border: 1px solid var(--mantine-color-neutral-5);
      border-radius: 0px !important;
    }
  }

  .mapboxgl-ctrl-group {
    // TODO migrate to var
    // This is here for default Mapbox controls
    border-radius: 1px !important;
    max-width: 32px;

    &:not(:empty) {
      // Nullify initial style
      box-shadow: unset !important;

      // Center the icon
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
    }

    & button {
      // width: 100% !important;
      // height: 40px !important;

      &+button {
        border-top: unset !important;
      }

      // Custom icons for default controls
      &.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 13H19V11H13H11H5V13H11V19H13V13ZM11 9H13V5H11V9Z' fill='white'/%3e%3c/svg%3e ");
      }

      &.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19 13H5V11H19V13Z' fill='white'/%3e%3c/svg%3e ");
      }

      &.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.2266 17.5V23.625H18.1016' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.6687 23.0655L17.2266 16.625' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.97656 10.5V4.375H11.1016' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.53516 4.93408L11.9773 11.3746' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.1016 4.375H24.2266V10.5' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.6671 4.93262L17.2266 11.3748' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.1016 23.625H4.97656V17.5' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.53516 23.0672L11.9757 16.625' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      }

      &.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2266 22.75V16.625H23.3516' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.7852 17.1846L24.2273 23.6251' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.9766 5.25V11.375H5.85156' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.4187 10.8155L4.97656 4.375' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.3516 11.375H17.2266V5.25' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.7852 10.8172L24.2257 4.375' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.85156 16.625H11.9766V22.75' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.4171 17.1826L4.97656 23.6248' stroke='%23101213' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      }
    }
  }

  .mapboxgl-ctrl-bottom-left {
    display: flex;
    flex-direction: column-reverse;
  }

  .mapbox-ctrl-minimap {
    order: 2;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-group {
    width: 32px;
    height: 32px;

    svg path {
      fill: white;
    }
  }

  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 10px 24px !important;
  }

  #bottom-left-control-container {
    position: absolute;
    bottom: 24px;
    margin-bottom: 0 !important;
    flex-direction: column-reverse !important;
  }

  .mapboxgl-ctrl-logo {
    position: absolute;
    bottom: -5px;
  }
}
