@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-rt {
  --ag-background-color: 'transparent';
  --ag-foreground-color: var(--mantine-color-white);
  --ag-header-background-color: 'transparent';
  --ag-header-foreground-color: var(--mantine-color-neutral-5);
  --ag-borders: 'none';
  --ag-border-color: 'transparent';
  --ag-borders-row: 'none';
  --ag-row-border-color: 'transparent';
  --ag-checkbox-background-color: var(--mantine-color-neutral-8);

  & .ag-header-cell-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; // Mantine don't expose css variables for "other" variables
    font-size: var(--mantine-font-size-sm);
    text-transform: uppercase;
  }

  & .ag-header {
    text-transform: uppercase;
  }

  & .ag-header-row {
    font-weight: 400;
  }

  & .ag-row {
    display: flex;
    align-items: center;
    background-color: var(--mantine-color-neutral-8);
    // max-height: 56px;
    border: .5px solid var(--mantine-color-neutral-7);
  }

  & .firstColFullWidth {
    width: 100% !important
  }

  & .ag-cell {
    display: flex;
    align-items: center;
    padding-top: var(--ag-widget-container-vertical-padding);
    padding-bottom: var(--ag-widget-container-vertical-padding);
  }

  & .ag-right-aligned-cell {
    justify-content: flex-end;
  }

  & .ag-paging-panel {
    display: flex;
    justify-content: space-between;
    border: none;

    &>.ag-paging-row-summary-panel {
      margin: 0;
      color: var(--mantine-color-neutral-5);

      &>* {
        font-weight: 400;
      }
    }

    &>.ag-paging-page-summary-panel {
      margin: 0;
      color: var(--mantine-color-neutral-5);
    }

    & .ag-paging-button {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--mantine-color-blue-6);
    }
  }

  //noinspection ALL
  & .ag-icon-next {
    background: transparent url("/packages/common/icons/Arrows/collapse-arrow-right.svg") center/contain no-repeat;
    background-size: 12px;
    color: transparent;
  }

  //noinspection ALL
  & .ag-icon-previous {
    background: transparent url("/packages/common/icons/Arrows/back-arrow.svg") center/contain no-repeat;
    background-size: 12px;
    color: transparent;
  }

  //noinspection ALL
  & .ag-icon-first {
    background: transparent url("/packages/common/icons/chevron-left.svg") center/contain no-repeat;
    color: transparent;
  }

  //noinspection ALL
  & .ag-icon-last {
    background: transparent url("/packages/common/icons/chevron-right.svg") center/contain no-repeat;
    color: transparent;
  }

  //noinspection ALL
  & .ag-icon-asc {
    background: transparent url("/packages/common/icons/circle-sort-ascending.svg") center/contain no-repeat;
    background-size: 12px;
    color: transparent;
  }

  //noinspection ALL
  & .ag-icon-desc {
    background: transparent url("/packages/common/icons/circle-sort-descending.svg") center/contain no-repeat;
    background-size: 12px;
    color: transparent;
  }

  & .ag-sort-none-icon {
    background: transparent url("/packages/common/icons/circle-sort.svg") center/contain no-repeat;
    background-size: 12px;
    color: transparent;
  }

  & .ag-icons-small {
    --ag-icon-size: 16px !important;
  }

  & .ag-icons-large {
    --ag-icon-size: 24px !important;
  }
}

.ag-inverted-theme-rt {
  --ag-background-color: 'transparent';
  --ag-foreground-color: var(--mantine-color-white);
  --ag-header-background-color: 'transparent';
  --ag-header-foreground-color: var(--mantine-color-neutral-5);
  --ag-borders: 'none';
  --ag-border-color: 'transparent';
  --ag-borders-row: 'none';
  --ag-row-border-color: 'transparent';

  & .ag-row {
    background-color: var(--mantine-color-neutral-8) !important;
    border: .5px solid var(--mantine-color-neutral-6) !important;
  }

  .ag-icons-small {
    --ag-icon-size: 16px !important;
  }

  .ag-icons-large {
    --ag-icon-size: 24px !important;
  }
}
