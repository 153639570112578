@import "../../../../core/styles/resources/media";
@import "../../../../core/styles/resources/ag-grid";

.visx-tooltip {
  z-index: 9999; /* or whatever height is appropriate */
}

/* stylelint-disable */
.mapboxgl-popup {
  max-width: unset !important;
}

#indoor-map-floor-selector {
  flex: 1;
  margin-left: 0 !important;
}

.miniMap-and-risk-timeline-controls-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

#mid-right-control-container {
  transform: translateY(35vh);
}
