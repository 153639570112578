@import "../../styles/resources/media";
@import "../../styles/resources/ag-grid";

.visx-tooltip {
  z-index: 9999; /* or whatever height is appropriate */
}

.Router {
  &-NavigationWrapper {
    position: relative;

    display: flex;
    width: 100%;
    height: fit-content;
  }

  &-PageWrapper {
    margin-left: var(--navigation-sidebar-closed-width);
    max-width: calc(100% - var(--navigation-sidebar-closed-width));

    flex: auto;
    height: 100vh;
  }

  &-Nav {
    z-index: 195; //mantine-modal uses 200. Navbar should appear behind the modal.

    position: fixed;
    top: 0;

    height: 100vh;
  }
}
