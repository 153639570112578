@import "../../styles/resources/media";
@import "../../styles/resources/ag-grid";

.visx-tooltip {
  z-index: 9999; /* or whatever height is appropriate */
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import '@aerisweather/mapsgl/dist/mapsgl.css';

/* stylelint-disable-next-line selector-class-pattern */
:root .dark-mode {
  --brand-bg-color: var(--color-brand-primary-base);
  --brand-fg-color: var(--color-neutral-0);
  --main-bg-color: #202124; // TODO remove
  --main-text-color: var(--color-neutral-0);
  --panel-bg-color: #24292e; // TODO remove
  --panel-bg-color-standout: #070515; // TODO remove
  --offline-bg-color: var(--color-error-base);
  --offline-fg-color: var(--color-neutral-0);
  --online-bg-color: var(--color-success-base);
  --online-fg-color: var(--color-neutral-0);
  --panel-box-shadow: #111 3px 1px 9px 0; // TODO remove
  --button-bg: var(--color-brand-secondary-base);
  --button-sec-bg: #212938; // TODO remove
  --button-bg-disabled: var(--color-neutral-2);
  --button-border-disabled: 1px solid var(--color-neutral-10);
}

/* stylelint-disable-next-line selector-class-pattern */
:root .light-mode {
  --brand-bg-color: var(--color-brand-primary-base);
  --brand-fg-color: var(--color-neutral-0);
  --main-bg-color: #c0c0c0ff; // TODO remove
  --main-text-color: var(--color-neutral-10);
  --panel-bg-color: var(--color-neutral-0);
  --panel-bg-color-standout: #c0c0c0ff; // TODO remove
  --offline-bg-color: var(--color-error-base);
  --offline-fg-color: var(--color-neutral-0);
  --online-bg-color: var(--color-success-base);
  --online-fg-color: var(--color-neutral-0);
  --panel-box-shadow: #bfc4ca 3px 1px 9px 0; // TODO remove
  --button-bg: var(--color-brand-secondary-base);
  --button-sec-bg: var(--color-neutral-0);
  --form-page-bg-color: var(--color-neutral-0);
  --form-panel-border-colour: var(--color-neutral-4);
  --form-panel-bg-colour: var(--color-neutral-1);
  --field-display-only-bg-color: var(--color-neutral-2);
  --field-display-only-border-color: var(--color-neutral-4);
  --button-bg-disabled: var(--color-neutral-3);
  --button-border-disabled: var(--color-neutral-6);
}

:root {
  --page-padding: 16px;
  --default-transition-speed: 0.225s;
}

body {
  font-size: var(--fs-body);
  color: var(--main-text-color);
}

// TODO move everything below this to global
/* stylelint-disable selector-class-pattern */
.d-flex {
  display: flex;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.d-flex-align-bottom {
  display: flex;
  align-items: flex-end;
}

.w-100 {
  width: 100%;
}
